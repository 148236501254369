<!-- 平行会议--暂时取消 -->
<template>
    <div class="parallelMeetings">
        <el-row>
            <el-col :span="24" :lg="24">
                <div class="pageBox">
                    <div class="header">
                        <el-image style="width: 100%;" :src="require('../../assets/images/parallelMeetings/banner.png')"
                            :fit="'fill'">
                        </el-image>
                        <div class="title">平行会议</div>
                    </div>
                    <div class="lcontainer container">
                        <div class="title">平行会议召集通知</div>
                        <p class="m-t-38 m-b-24">
                            由国际竹藤组织与国家林业和草原局联合主办的第二届世界竹藤大会将于2022年11月7日—8日在北京国际会议中心召开。大会将汇聚各国政府部门、可持续发展践行者、科研院所和私营部门等在内的各界代表，分享和交流竹藤领域最新进展，共商后疫情时代经济复苏，促进竹藤产业持续发展，助力实现联合国可持续发展目标。
                        </p>
                        <p class="m-b-24">
                            第二届世界竹藤大会的主题为“竹藤——基于自然的可持续发展解决方案”。国际竹藤组织和中国国家林草局诚挚邀请和热情欢迎所有竹藤行业、林业及其他相关行业代表和机构参加第二届世界竹藤大会，并在大会期间组办平行会议。
                        </p>
                        <p>本届大会共设置40场平行会议。拟申办的平行会议应紧扣大会主题，至少与以下其中一个大会专题领域相关。</p>


                        <div class="subtitle-box">
                            <div class="subbtitle m-t-34 m-b-14">
                                专题一、迈向碳中和之路
                            </div>
                            <p>专题一将在推进碳中和目标的大背景下，重点探讨竹子在助力全球减缓和适应气候变化、保护森林和恢复景观、转变低碳生活方式、实现碳达峰碳中和目标，以及保护生物多样性和提升生态系统服务等领域的作用与贡献。
                            </p>
                            <div class="subbtitle m-t-34 m-b-14">
                                专题二、助推绿色经济发展
                            </div>
                            <p>专题二着眼于如何利用竹藤资源减少贫困、振兴乡村、促进可持续生活并推动新型绿色经济。专题内容包括竹藤资源可持续经营、基于竹藤的农林复合系统、小农户生计发展、竹藤中小型企业发展、竹藤推进可持续就业和促进绿色价值链的案例研究、生态旅游与竹藤文化产业等。
                            </p>

                            <div class="subbtitle m-t-34 m-b-14">
                                专题三、创新材料与市场开发
                            </div>
                            <p>专题三重点探讨竹藤在不同领域的新突破、新发展，包括新型应用、创新产品、制造前沿、竹藤产业规模化、集群化及循环经济发展等，以满足全球日益增长的对生物基低碳产品的需求。专题内容包括以竹代塑、以竹代木、以竹代棉、以竹代钢材和混凝土建材等创新应用，竹藤产品相对于传统材料和能源的比较优势，竹藤食品研究前沿，竹藤生物物理化学利用进展，竹藤产品标准、认证及市场准入，竹藤产业发展未来趋势等。
                            </p>
                            <div class="subbtitle m-t-34 m-b-14">
                                专题四、共促产业和谐包容发展
                            </div>
                            <p>专题四旨在展示并分享全球竹藤可持续发展的国际合作与交流经验。专题内容包括竹藤知识和最佳实践分享，能力建设与技术转让，妇女和社区在竹藤产业发展中的作用，“一带一路”、南南合作与多边合作案例研究，公私伙伴关系在竹藤产业发展中的作用，投资促进和伙伴关系发展，国际竹藤产业价值链和国际贸易便利化等。
                            </p>
                            <div class="attention g-color m-t-24">注：鉴于新冠疫情防控需要，大会将采取线上或线上的形式举行。</div>
                        </div>

                        <div class="subtitle-box">
                            <div class="subtitle">
                                组办平行会议将有机会享有以下权益
                            </div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>加入竹藤对可持续发展贡献的全球对话； </div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>与众多致力于可持续发展的政策制定者、研究人员和行业领导者建立联系；
                            </div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>展现其成功举措、创新案例和可持续发展方案等； </div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>展示其在相关领域中的专业知识并扩大影响力； </div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>拓展潜在的合作伙伴，与之建立联系并进行互动； </div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>通过大会官方宣传渠道提高其媒体曝光度。 </div>
                        </div>

                        <div class="subtitle-box">
                            <div class="subtitle">
                                申请流程
                            </div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>平行会议方案征集期为2022年5月10日至2022年6月30日。
                            </div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>请在大会网站下载<span class="a-color">
                                    “平行会议申办表” （在此下载）</span> ，并将填写完整的“ <span class="a-color">平行会议申办表</span>”发送电子邮件至 <span
                                    class="g-color">barc2022_sessions@inbar.int</span> 。 </div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>平行会议申办表的提交截止日期为 <span
                                    class="a-color">2022年6月30日北京时间24时</span>。由于平行会议场次数量有限，大会组委会建议申办单位尽早提交申请表。
                            </div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>大会组委会将对收到的平行会议申请进行评估，最迟将于 <span
                                    class="a-color">2022年7月30日</span>确认并通知获准举办平行会议的申请单位。 </div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>大会组委会将通过电子邮件通知平行会议申请评估结果，因此请务必确保
                                “ <span class="a-color">平行会议申办表</span>”中提供的联系人电子邮件地址填写正确。 </div>
                            <div class="flex a-c m-b-24"><span
                                    class="dot"></span>获准的平行会议将收录至大会日程中。根据大会日程需要，大会组委会保留对平行会议的形式、内容、日程安排等的修改建议和调整权力。
                            </div>
                        </div>


                        <div class="subtitle-box">
                            <div class="subtitle">
                                申办平行会议的一般性指南
                            </div>
                            <div class="flex a-c m-b-24"><span
                                    class="dot"></span>任何从事竹藤、林业或相关领域工作的政府间国际组织、多边或双边发展组织、非政府组织、政府部门、科研院所、高校、协会、社会团体和企业等均可申请举办平行会议。
                            </div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>所申请的平行会议应至少与其中一个上述大会专题领域相关。</div>
                            <div class="flex a-c m-b-24"><span
                                    class="dot"></span>平行会议方案内容应包括会议的组织者、目标、内容、形式和拟定的演讲者等基本信息。</div>
                            <div class="flex a-c m-b-24"><span
                                    class="dot"></span>每场平行会议时长应在1.5-2小时，建议每位演讲嘉宾的报告时长在15-20分钟内为佳。</div>
                            <div class="flex a-c m-b-24"><span
                                    class="dot"></span>平行会议应以英文或中文进行，并为与会者提供英文或中文的同传，也可自行安排提供其他语言的同传。</div>
                        </div>

                        <div class="subtitle-box">
                            <div class="subtitle">
                                平行会议审批准则
                            </div>
                            <div class="secondarytitle">
                                大会组委会将依据以下标准，审阅<span class="g-color">申办表填写完整</span>的平行会议方案。
                            </div>

                            <div class="flex a-c m-b-24"><span class="dot"></span>平行会议方案质量，包括其科学性；
                            </div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>国际热点问题及与INBAR成员国的相关性和参与度；</div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>激发参会兴趣和热烈讨论的能力；</div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>会议演讲者的地域与性别多样性；</div>
                            <div class="flex a-c m-b-24"><span
                                    class="dot"></span>多方利益相关方的参与（例如包含科学家、政策制定者和来自社会团体和产业的代表）；</div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>集聚媒体报道的可能性；</div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>优先考虑多个单位合办的平行会议，鼓励更多的单位参与。</div>
                        </div>


                        <div class="subtitle-box">
                            <div class="subtitle">
                                平行会议组办单位的责任
                            </div>
                            <p class="m-b-24">
                                第二届世界竹藤大会组委会将为所有平行会议的成功举办保障后勤，包括提供会议室、投影仪、会议电脑、同传设备、线上平台以及会议视听设备等。平行会议的组办单位需负责以下工作：</p>
                            <div class="flex a-c m-b-24"><span class="dot"></span>邀请演讲嘉宾、对话嘉宾等，并在需要时安排其差旅；</div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>邀请来自INBAR成员国和其他利益相关方的与会者；</div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>准备一份平行会议的总结报告，并在会议结束后3天内发给大会组委会；</div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>组织平行会议相关宣传，制作宣传材料（例如会议传单、海报等）和媒体报道；
                            </div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>如选择线上线上会议形式，组办单位应承担由此产生的相关设备服务费用。
                            </div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>大会组委会提供同传设备，平行会议组办单位应承担翻译员费用。</div>
                            <div class="flex a-c m-b-24"><span class="dot"></span>如需其他额外的设备和服务，在能满足的条件下组办者应承担由此产生的费用。
                            </div>
                            <div class="secondarytitle">
                                “ <span class="a-color">平行会议申办表</span> ”应在2022年6月30日前发送电子邮件到 <span class="g-color">barc2022_sessions@inbar.int</span> 
                            </div>
                            <div class="secondarytitle">
                                如有任何问题，请联系平行会议负责人李艳霞女士，<span class="g-color">yxli@inbar.int</span> 
                            </div>
                            <div class="secondarytitle">
                                我们期待您的参与，更期待您申办平行会议！
                            </div>
                        </div>

                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    }
}
</script>
<style lang="stylus" scoped>
    .parallelMeetings
      width 100%;
      overflow hidden;
      .pageBox
        .header
          position: relative
          overflow hidden
          .title
            position absolute
            top 50%
            left 50%
            color #fff
            width 194px
            height 80px
            line-height 80px
            transform: translate(-50%,-50%)
            text-align center;
            background: rgba(141, 198, 63, 0.9);
            font-weight: 700;
            font-size: 42px;
            font-family: 'Microsoft YaHei'
        .container
            margin-top: 36px
            margin-bottom: 36px
            padding: 56px 44px
            text-align: left
            .title
              font-family: 'Calibri'
              font-style: normal
              font-weight: 700
              font-size: 32px
              line-height: 39px    
              color: #405E3C
              text-align:  center
            .subtitle-box
              text-align left
              .subtitle
                font-size: 28px
                color: #405E3C
                margin: 43px 0 29px 0
              .secondarytitle
               font-size: 20px
               margin-bottom: 22px
               font-weight: 700
</style>